import React from 'react';
import { AppBar, Box, Button, Checkbox, FormControlLabel, Toolbar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

type DesktopActionBarProps = {
  setIsOpen: (value: boolean) => void;
  setShowInactive: (value: boolean) => void;
}
const DesktopActionBar = ({ setIsOpen, setShowInactive }: DesktopActionBarProps) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            style={{backgroundColor: 'green'}}
            onClick={() => setIsOpen(true)}
            size="small"
          >
            Add User
          </Button>
          <div style={{ flexGrow: 1 }} />
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => setShowInactive(e.target.checked)}
                  color='default'
                  defaultChecked={false}
                  sx={{
                    color: 'white'
                  }}
                />
              }
              label="Show inactive users"
            />
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default DesktopActionBar;
