import React from 'react';
import MobileVenueCard from './MobileVenueCard';
import { CircularProgress, Typography } from '@mui/material';

type MobileVenueSettingsProps = {
  venues?: any[];
  activeUids: string[];
  archivedUids: string[];
  reservationCount: any;
  isLoading: boolean;
};

const centerStyle = {
  display: 'flex', justifyContent: 'center', marginTop: '50px'
};

const MobileVenueSettings: React.FC<MobileVenueSettingsProps> = ({
  venues,
  activeUids,
  archivedUids,
  reservationCount,
  isLoading
}) => {
  if (isLoading) {
    return (
      <div style={centerStyle}>
        <CircularProgress />
      </div>
    );
  }
  if (!venues || venues.length === 0) {
    return (
      <div style={centerStyle}>
        <Typography variant="h6">No Venues are found.</Typography>
      </div>
    );
  }
  return (
    <div>
      {
        venues?.map(venue => <MobileVenueCard venue={venue} activeUids={activeUids} archivedUids={archivedUids} reservationCount={reservationCount} />)
      }
    </div>
  );
}

export default MobileVenueSettings;
