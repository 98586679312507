import React, { useEffect, useState, useContext, useCallback, useRef } from 'react';

import { FirebaseContext } from '../firebase/FirebaseContext';

import { getVenueById } from '../utils';
import { ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { IdleMonitor } from './IdleMonitor';

import moment from 'moment';
import { AccountMenu } from './AccountMenu';

export const ManagerHeader: React.FC<any> = ({ handleVenueChange }) => {
  const [selectedVenue, setSelectedVenue] = useState<any>();
  const [idleMonitorEnabled, setIdleMonitorEnabled] = useState<boolean>(false);
  const [showIdleModal, setShowIdleModal] = useState<boolean>(false);
  const [venueList, setVenueList] = useState<any[]>([]);
  const { currentManager, db } = useContext(FirebaseContext);
  const timeoutIntervalRef = useRef<number | undefined>(undefined);
  const ALL_VENUES = 'All Venues';

  const getAsyncVenue = useCallback(async (venueUid: string) => {
    if (venueUid === ALL_VENUES) {
      setSelectedVenue(ALL_VENUES);
    } else if (!!venueUid) {
      const venue = await getVenueById(db, venueUid);
      setSelectedVenue(venue);
    }
  }, [db])

  useEffect(() => {
    if (selectedVenue === ALL_VENUES) {
      handleVenueChange(currentManager.venues);
    } else if (!!selectedVenue) {
      handleVenueChange([selectedVenue]);
    }
  }, [handleVenueChange, selectedVenue, currentManager.venues]);

  const getVenueLogo = useCallback(() => {
    if (selectedVenue && selectedVenue === ALL_VENUES) {
      return <img alt={'Rezylist'} src={'https://rezylist.com/static/media/logo.50b731eddd7ec5258825.png'} style={{maxHeight: 132}} />
    }
    if (selectedVenue &&  selectedVenue.venue_logo) {
      return <img alt={selectedVenue.venue_name} src={selectedVenue.venue_logo} style={{ height: 132 }} />
    }
    else return <span />
  }, [selectedVenue])

  useEffect(() => {
    
    if (currentManager && currentManager.venues && currentManager.venues.length > 0) { 
      const menuList = currentManager.venues.sort();
      if (menuList.length > 1 && menuList[0] !== ALL_VENUES) { menuList.unshift(ALL_VENUES); }
      setVenueList(menuList);
      getAsyncVenue(menuList[0]); 
    }
  }, [getAsyncVenue, currentManager]);

  useEffect(() => {
    timeoutIntervalRef.current = window.setInterval(() => {
      let isValid = currentManager && currentManager.last_login && moment(currentManager.last_login.toDate()).isAfter(moment().subtract(7, 'days'));
      if (!isValid) {
        setIdleMonitorEnabled(true);
        clearInterval(timeoutIntervalRef.current)
      }
    }, (20*60*1000)); // check every 20 min
  
    return () => clearInterval(timeoutIntervalRef.current);
  }, [currentManager]);

  const handleChange = async (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    await getAsyncVenue(value);
  };

  const handleIdleMonitorChange = (val: boolean) => {
    setShowIdleModal(val);
  }
  return (
    <section>
      <div
        id='landing-header'
        style={{
          textAlign: 'center',
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
          background: selectedVenue && selectedVenue.venue_bgcolor ? 'black' : 'white',
          border: 'solid 1px #ccc',
          padding: 16,
          height: 170
        }}
      >
        <div>
          {getVenueLogo()}
        </div>
        <div style={{
          display: 'flex',
          minHeight: 120,
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'flex-end'
        }}>
          <AccountMenu venue={selectedVenue} />
          {venueList && venueList.length > 1 &&
            <Select
              id="venueselection"
              name="venueselection"
              value={selectedVenue && selectedVenue !== ALL_VENUES ? selectedVenue.uid : selectedVenue || ''}
              onChange={handleChange}
              MenuProps={{
                sx: {
                    '& .MuiMenu-paper': {
                        // backgroundColor: 'dark.primary',
                    },
                    '& .MuiMenuItem-root:hover': {
                        backgroundColor: '#ccc',
                    },
                    '& .Mui-selected': {
                        // color: 'white'
                    }
                }
              }}
              sx={{
                  color: selectedVenue && selectedVenue.venue_bgcolor ? 'white' : 'black',
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: selectedVenue && selectedVenue.venue_bgcolor ? 'white' : 'black',
                  },
                  '.MuiSvgIcon-root': {
                      color: selectedVenue && selectedVenue.venue_bgcolor ? 'white' : 'black'
                  },
                  '.MuiSelect-outlined': {
                    padding: '4px 24px'
                  }
              }}
            >
              {venueList?.length > 0 && venueList.map((item: string) => (
                <MenuItem key={item} value={item}>
                  <ListItemText>{item}</ListItemText>
                </MenuItem>
              ))}
            </Select>
          }
          
        </div>
      </div>
      {idleMonitorEnabled 
        ? <IdleMonitor 
            openModal={showIdleModal} 
            toggleShowIdleModal={handleIdleMonitorChange} />
        : <></>
      }
    </section>
  );
};
