import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { arrayUnion, doc, getDoc, updateDoc } from 'firebase/firestore';

import { showSuccessToast } from '../utils';
import { DemoReservationSearch } from './reservationsearch';
import { DemoSorry } from './sorry';
import { DemoWelcome } from './welcome';
import { FirebaseContext } from '../firebase/FirebaseContext';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import { DemoContainer, DemoWaiting } from './components';
import { DemoNoAvailability } from './noavailability';
import { DemoWaitlistForm } from './waitlistform';
import { DemoWaitlistSuccess } from './waitlistsuccess';
import { DemoOneDone } from './partone_done';
import { DemoActivateAlert } from './activatealert';
import { DemoCheckYourAlerts } from './checkyouralerts';
import { DemoTwoDone } from './parttwo_done';
import { DemoDashboardVideo } from './dashboardvideo';
import { DemoTrialForm } from './trialform';
import { httpsCallable } from 'firebase/functions';

export function DemoIndex() {
    const navigate = useNavigate();
    const { venueGuid } = useParams();
    const [checkin, setCheckin] = useState<Date | null>(null);
    const [checkout, setCheckout] = useState<Date | null>(null);
    const [stageIndex, setStageIndex] = useState(0);
    const [demoSite, setDemoSite] = useState<any>();
    const [showSorry, setShowSorry] = useState<boolean>(false);
    const { db, functions } = useContext (FirebaseContext);

    const checkSiteGuid = useCallback(async (docId: any) => {        
        const siteRef = doc(db, 'demo_sites', docId)
        const demoSiteSnap = await getDoc(siteRef)
        await sleep(500)
        if (!demoSiteSnap.exists()) { return setShowSorry(true) }
        const data = demoSiteSnap.data();
        await updateDoc(siteRef, {
            visits: arrayUnion(new Date())
        });
        if (!data.expiration || moment(data.expiration?.toDate()).isBefore(moment())) {
            return setShowSorry(true)
        }
        setDemoSite(data);
        setStageIndex(1);
    }, [db])

    useEffect(() => {
        if (!venueGuid) { setShowSorry(true) }
        else {
            checkSiteGuid(venueGuid)
        }

    }, [checkSiteGuid, venueGuid]);


    const goToNext = () => {
        setStageIndex(stageIndex + 1)
    }

    const handleDemoMessage = async (data: any) => {
        const sendDemoMessage = httpsCallable(functions, 'notification-sendDemoMessage');
        sendDemoMessage(data)
    }
    const stageMap = [
        <DemoWaiting />,
        <DemoWelcome demoSite={demoSite} onNextClick={() => goToNext()} />,
        <DemoReservationSearch onNextClick={(props: any) => {
            if (props.checkin && props.checkout) {
                setCheckin(props.checkin)
                setCheckout(props.checkout)
            }
            goToNext()
        }} />,
        <DemoNoAvailability onNextClick={() => goToNext()} />,
        <DemoWaitlistForm checkin={checkin} checkout={checkout} onNextClick={() => goToNext()} />,
        <DemoWaitlistSuccess onNextClick={() => goToNext()} />,
        <DemoOneDone onNextClick={() => goToNext()} />,
        <DemoActivateAlert onNextClick={() => goToNext()} />,
        <DemoCheckYourAlerts onNextClick={() => goToNext()} />,
        <DemoTwoDone onNextClick={() => goToNext()} />,
        <DemoDashboardVideo onNextClick={() => goToNext()} />,
        <DemoTrialForm onNextClick={async (data: any) => {
            await handleDemoMessage(data)
            showSuccessToast('Success!  Thank you for your message.  We will be in touch.');
            navigate('/');
        }} />,
    ]

    const sleep = (ms: number) => {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    return (
      <>
        <Helmet>
            <title>{demoSite?.venue || 'RezyList '} Waitlist Demo</title>
        </Helmet>
        <DemoContainer>
            {showSorry ? 
                <DemoSorry onNextClick={async (data: any) => {
                    await handleDemoMessage(data)
                    showSuccessToast('Success!  Thank you for your message.  We will be in touch.');
                    navigate('/');
                }} /> 
            : 
                stageMap[stageIndex]
            }
        </DemoContainer> 
      </>
    );
};
