import React from 'react';
import { DataGridPro, GridCellEditStartParams, GridColDef, MuiEvent } from '@mui/x-data-grid-pro';
import { Box, CircularProgress, Paper, TableContainer } from '@mui/material';

type DesktopVenueSettingsProps = {
  venues?: any[];
  activeUids: string[];
  archivedUids: string[];
  reservationCount: any;
};
const DesktopVenueSettings: React.FC<DesktopVenueSettingsProps> = ({
  venues,
                                               activeUids,
                                               archivedUids,
                                               reservationCount
                              }) => {
  const columns: GridColDef[] = [
    {
      field: 'venue_icon',
      headerName: 'Icon',
      headerAlign: 'right',
      width: 60,
      minWidth: 80,
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div style={{padding: 4}}>
            {params.row.venue_icon ? <img style={{height: 'auto', width: 30}} src={params.row.venue_icon} alt='Venue Icon' /> : <></>}
          </div>
        )
      }
    },
    {
      field: 'venue_logo',
      headerName: 'Logo',
      headerAlign: 'center',
      flex: 0.5,
      minWidth: 150,
      editable: false,
      valueGetter: (params) => {
        return params.row.uid
      },
      renderCell: (params) => {
        return params.row.venue_logo ?
          <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '4px 2px', backgroundColor: params.row.venue_bgcolor ? 'black' : 'white'}}>
            <div><img style={{width: '100%', height: 32}} src={params.row.venue_logo} alt='Venue Logo' /></div>
          </div>
          : <></>
      }
    },
    {
      field: 'venue_name',
      headerName: 'Name',
      flex: 0.5,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      renderCell: (params) => {
        return (
          <div style={{textAlign: 'center', flexDirection: 'column', display: 'flex'}}>
            <div>{params.row.venue_name}</div>
            <div>
              {params.row.skip_availability ? <div style={{color: 'red', fontWeight: 'bold'}}>Manual Availability Alerts</div> : ''}
            </div>
          </div>
        )
      }
    },
    {
      field: 'venue_admin',
      headerName: 'Admin',
      flex: 0.5,
      minWidth: 150,
      headerAlign: 'center',
      editable: false,
      renderCell: (params) => {
        return (
          <div style={{width: '100%', textAlign: 'center'}}>
            {params.row.venue_admin.map((item:any, index: number) => (
              <div key={index}>{item}</div>
            ))}
          </div>
        )
      },
    },
    { field: 'activeCount',
      headerName: 'Active Requests',
      headerAlign: 'center',
      flex: 0.25,
      minWidth: 150,
      valueGetter: (params) => {
        return activeUids.filter(x => x === params.row.uid).length
      },
      renderCell: (params) => {
        return <div style={{width: '100%', textAlign: 'center'}}>{params.value}</div>
      }
    },
    { field: 'archivedCount',
      headerName: 'Archived Requests',
      headerAlign: 'center',
      flex: 0.25,
      minWidth: 150,
      valueGetter: (params) => {
        return archivedUids.filter(x => x === params.row.uid).length
      },
      renderCell: (params) => {
        return <div style={{width: '100%', textAlign: 'center'}}>{params.value}</div>
      }
    },
    { field: 'totalRequests',
      headerName: 'Total Requests',
      headerAlign: 'center',
      flex: 0.25,
      minWidth: 150,
      valueGetter: (params) => {
        return activeUids
          .filter(x => x === params.row.uid)
          .concat(archivedUids.filter(x => x === params.row.uid)).length
      },
      renderCell: (params) => {
        return <div style={{width: '100%', textAlign: 'center'}}>{params.value}</div>
      }
    },
    { field: 'reservation_count',
      headerName: 'Reservations',
      headerAlign: 'center',
      flex: 0.25,
      minWidth: 150,
      valueGetter: (params) => {
        return Number(reservationCount[params.row.uid] || 0)
      },
      renderCell: (params) => {
        return <div style={{width: '100%', textAlign: 'center'}}>{params.value}</div>
      }
    },
  ]

  return (
    <TableContainer component={Paper} sx={{width: '100%', padding: 2}}>
      {venues ? <Box
          sx={{
            width: '100%',
            '& .rz_bold': {
              fontWeight: 'bold',
            },

          }}>
          <DataGridPro
            autoHeight
            disableColumnSelector={true}
            getRowId={(row) => row.uid}
            disableRowSelectionOnClick
            rows={[...venues]}
            columns={columns}
            pagination
            pageSizeOptions={[20, 50, 100]}
            initialState={{
              pagination: { paginationModel: { pageSize: 20 }},
              sorting: {
                sortModel: [{ field: 'waitlistCount', sort: 'desc' }],
              },
            }}
            onCellEditStart={(params: GridCellEditStartParams, event: MuiEvent) => {
              event.defaultMuiPrevented = true;
            }}
          />
        </Box>
        : <div style={{height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}> <CircularProgress /> </div> }
    </TableContainer>
  )
};

export default DesktopVenueSettings;
