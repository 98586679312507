
import { httpsCallable } from 'firebase/functions';
import React, { useContext } from 'react';
import { FirebaseContext } from '../firebase/FirebaseContext';
import { DemoButton, DemoText } from './components';
import { CircularProgress } from '@mui/material';

export function DemoActivateAlert({onNextClick}: any) {
  const { functions } = useContext(FirebaseContext);
  const [saving, setSaving] = React.useState<boolean>(false);
  const triggerAlerts = async () => {
    const triggerdemonotifications = httpsCallable(functions, 'notification-triggerdemonotifications');
    triggerdemonotifications()
    onNextClick()
  }
  return (
    <>
        <DemoText>In this next part of the demo, a room opens up due to a cancellation and you will experience the waitlist system notifying of availability and directing you (the guest) directly to the hotel to book the room that you have been waiting for.</DemoText>
        <DemoText>Our technology checks hotel inventory every 10 minutes for availability and notifies a waitlisted guest when the desired room becomes available.</DemoText>
        <DemoText>You will now activate the system to notify the guest (yourself) of room availability (the RezyList system will automatically do this when integrated with a hotel).</DemoText>
        <div style={{padding: 20, display: 'flex', justifyContent: 'center'}}>
          <DemoButton disabled={saving} onClick={() => { setSaving(true); triggerAlerts(); }} variant="contained">
            {saving ? <CircularProgress /> : `Continue`}
          </DemoButton>
        </div>
    </>
  );
};
