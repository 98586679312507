import React, { useContext, useState } from 'react';
import { FirebaseContext } from '../../firebase/FirebaseContext';
import { collection, collectionGroup, getDocs, onSnapshot, query } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { actionTypes, getManagerById, isVenueAdmin } from '../../utils';
import DesktopVenueSettings from './DesktopVenueSettings';
import { useMobile } from '../../utils/useMobile';
import MobileVenueSettings from './MobileVenueSettings';

export const VenueSettings: React.FC<any> = () => {
  const [venues, setVenues] = useState<any[]>();
  const [isVenueLoading, setIsVenueLoading] = useState(true);
  const [activeUids, setActiveUids] = useState<string[]>([]);
  const [archivedUids, setArchivedUids] = useState<string[]>([]);
  const [reservationCount, setReservationCount] = useState<any>({});
  const navigate = useNavigate();
  const { isMobile } = useMobile();
  const { currentManager, db } = useContext (FirebaseContext);

  React.useEffect(() => {
    if (currentManager && !isVenueAdmin(currentManager)) { 
      navigate('/settings')
      return;
    }
    const unsub = onSnapshot(
      query(collection(db, 'venues')),
      async (venueSnap) => {
        setIsVenueLoading(true);
        const venueDataset = venueSnap.docs.filter(y => currentManager[y.id] && 
                                                currentManager[y.id].includes(actionTypes.VENUE_ADMIN) &&
                                                currentManager.venues.includes(y.id))
                                            .map(x => ({uid: x.id, ...x.data()}));
        const managersSnap = await getDocs(collection(db, 'managers'));
        const mgrDataset = managersSnap.docs.map(x => ({uid: x.id, ...x.data()}));
        const newVenuesList = [];
        for (const venueData of venueDataset) { 
          let newItem:any = {...venueData}
          newItem.venue_admin = [];
          const mgrList = mgrDataset.filter((x:any) => x.venues?.includes(venueData.uid) && !x.is_deactivated)
          for (let i = 0; i < mgrList.length; i++) {
            const mgrData:any = await getManagerById(db, mgrList[i].uid || '');
            if (mgrData && mgrData[venueData.uid] && mgrData[venueData.uid].includes(actionTypes.VENUE_ADMIN)) {
              newItem.venue_admin.push(mgrData.first_name + ' ' + mgrData.last_name);
            }
          }
          newVenuesList.push(newItem);
        }
                                       
        setVenues(newVenuesList)
        setIsVenueLoading(false);
      }
    )
    return () => unsub()
  }, [currentManager, db, navigate]);

  React.useEffect(() => {

    const unsub = onSnapshot(
      collectionGroup(db, 'waitlist'),
      (res) => {
        const actives:any[] = [];
        const archived:any[] = [];
        const rezylist:any = {};
        res.forEach(doc => {
          const data = doc.data()
          const uid = doc.ref.parent.parent?.id || ''
          if (data && !data.is_archived) {
            actives.push(uid)
          } else {
            archived.push(uid)
          }
          if (data.reservations) {
            rezylist[uid] = (rezylist[uid] || 0) + Object.keys(data.reservations).length; 
          }
        })
        setReservationCount(rezylist || {});
        setActiveUids(actives || []);
        setArchivedUids(archived || []);
      }
    );
          
    return () => unsub();
  }, [db, reservationCount, venues])

  // if isMobile then display as such
  return isMobile ?
    <MobileVenueSettings venues={venues} activeUids={activeUids} archivedUids={archivedUids} reservationCount={reservationCount} isLoading={isVenueLoading} /> :
    <DesktopVenueSettings venues={venues} activeUids={activeUids} archivedUids={archivedUids} reservationCount={reservationCount} />
};
