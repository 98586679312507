import React from 'react';

type ImageProps = {
  src: string;
  alt?: string;
  style?: React.CSSProperties;
  hideOnError?: boolean;
}
const Image = ({ src, alt, style, hideOnError }: ImageProps) => {
  return <img style={style} src={src} alt={alt} onError={({ currentTarget }) => {
    if (hideOnError) {
      currentTarget.style.display = 'none';
    }
  }} />;
};

export default Image;