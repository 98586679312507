
export const actionTypes = {
  ADD_WAITLIST_REQUEST: 'ADD_WAITLIST_REQUEST',
  DELETE_WAITLIST_REQUEST: 'DELETE_WAITLIST_REQUEST',
  EDIT_WAITLIST_REQUEST: 'EDIT_WAITLIST_REQUEST',
  DOWNLOAD_WAITLIST: 'DOWNLOAD_WAITLIST',
  VIEW_ARCHIVES: 'VIEW_ARCHIVES',
  NEW_WAITLIST_NOTIFICATION: 'NEW_WAITLIST_NOTIFICATION',
  NEW_RESERVATION_NOTIFICATION: 'NEW_RESERVATION_NOTIFICATION',
  VENUE_ADMIN: 'VENUE_ADMIN'
}
export const actionList = [
  {
    value: actionTypes.ADD_WAITLIST_REQUEST,
    label: 'Add waitlist requests',
    adminDefault: true
  },
  {
    value: actionTypes.DELETE_WAITLIST_REQUEST,
    label: 'Archive/Unarchive waitlist requests',
    adminDefault: true
  },
  {
    value: actionTypes.EDIT_WAITLIST_REQUEST,
    label: 'Edit existing waitlist requests',
    adminDefault: true
  },
  {
    value: actionTypes.DOWNLOAD_WAITLIST,
    label: 'Download waitlist to CSV',
    adminDefault: true
  },
  {
    value: actionTypes.VIEW_ARCHIVES,
    label: 'View Archives',
    adminDefault: true
  },
  {
    value: actionTypes.NEW_WAITLIST_NOTIFICATION,
    label: 'Receive notifications of new waitlist requests'
  },
  {
    value: actionTypes.NEW_RESERVATION_NOTIFICATION,
    label: 'Receive notifications of new reservations'
  },
]
export function isValidAction (actionType, currentManager, venueUid) {
  try {
    if (!actionType || !currentManager || !venueUid) {
      throw new Error('Invalid Request')
    }
    if (currentManager.is_super_admin) { return true; }
    const venues = currentManager.venues;
    if (!venues || !venues.includes(venueUid)) { return false; }
    const actionsList = currentManager[venueUid] || []
    return actionsList.includes(actionTypes.VENUE_ADMIN) || actionsList.includes(actionType);

  } catch (error) {
    // console.error('isValidAction - PERMISSIONS ERROR: ', actionType, currentManager, venueUid, error.message);
    return false;
  }
};
export function isVenueAdmin (currentManager) {
  try {
    if (!currentManager) {
      throw new Error('Invalid Request')
    }
    if (currentManager.is_super_admin) { return true; }
    const venues = currentManager.venues || [];
    for (let i = 0; i < venues.length; i++) {
      const uid = venues[i];
      const actionsList = currentManager[uid] || []  
      if (actionsList.includes(actionTypes.VENUE_ADMIN)) {
        return true;
      }
    }
    return false;
  } catch (error) {
    console.error('isVenueAdmin - PERMISSIONS ERROR: ', currentManager, error.message);
    return false;
  }
}
  
