import React, { useMemo } from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import Image from '../../components/Image';

type MobileVenueCardProps = {
  venue: any;
  activeUids: string[];
  archivedUids: string[];
  reservationCount: any;
};

const MobileVenueCard = ({ venue, activeUids, archivedUids, reservationCount }: MobileVenueCardProps) => {
  const activeRequests = useMemo(() => {
    return activeUids.filter(x => x === venue.uid).length;
  }, [venue, activeUids]);
  const archivedRequests = useMemo(() => {
    return archivedUids.filter(x => x === venue.uid).length;
  }, [venue, archivedUids]);

  return (
    <Card sx={{ marginBottom: 2}}>
      <CardActionArea>
        {venue.venue_logo && <CardMedia>
          <Image src={venue.venue_logo} alt={`${venue.venue_name} logo`} style={{height: '150px', width: '100%', backgroundColor: venue.venue_bgcolor ? 'black' : 'white' }} hideOnError={true} />
        </CardMedia>}
        <CardContent>
          <div style={{ display: 'flex' }}>
            <Image style={{height: 'auto', width: 30, backgroundColor: 'black', marginRight: '15px'}} src={venue.venue_icon} alt='Venue Icon' hideOnError={true} />
            <Typography variant="h6">{venue.venue_name}</Typography>
          </div>
          <div>
            <Typography variant="subtitle2">Active Requests: {activeRequests}</Typography>
            <Typography variant="subtitle2">Archived Requests: {archivedRequests}</Typography>
            <Typography variant="subtitle2">Total Requests: {activeRequests + archivedRequests}</Typography>
            <Typography variant="subtitle2">Reservations: {reservationCount[venue.uid] ?? 0}</Typography>
            <Typography variant="subtitle2">Admin(s): {venue.venue_admin?.join(", ")}</Typography>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default MobileVenueCard;
