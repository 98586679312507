import React from 'react';
import { actionTypes, cleanupWaitlistRecords, isValidAction } from '../../utils';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Paper,
  Popover,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Remove } from '../RemoveDialog';

const MobileActionsBar: React.FC<any> = ({
                            venueList,
                            venueUid,
                            records,
                            selected,
                            handleAddGuest,
                            handleShowHistorical,
                            handleFilterBooked,
                            currentManager,
                            handleRemoveSelected,
                          }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const showArchivedOption = React.useMemo(() => {
    if (venueUid) {
      return isValidAction(actionTypes.VIEW_ARCHIVES, currentManager, venueUid);
    }
    return venueList.some((x: string) => isValidAction(actionTypes.VIEW_ARCHIVES, currentManager, x));
  }, [venueList, venueUid, currentManager]);

  const isOpen = Boolean(anchorEl);
  return (
    <>
      {venueUid && isValidAction(actionTypes.ADD_WAITLIST_REQUEST, currentManager, venueUid) ? <Button
        startIcon={<AddIcon />}
        variant='contained'
        style={{ backgroundColor: 'green' }}
        onClick={() => handleAddGuest()}
      >
        Add Guest
      </Button> : <span></span>}
      {selected.length ? (
        <div style={{ display: 'flex', marginLeft: 20 }}>
          <Remove
            count={selected.length}
            onRemoveSelected={handleRemoveSelected}
          />
        </div>
      ) : null}
      <div style={{ flexGrow: 1 }} />
      {venueList && venueList.some((x: string) => isValidAction(actionTypes.DOWNLOAD_WAITLIST, currentManager, x)) ?
        <CSVLink
          title='Download CSV'
          style={{ margin: '16px 0px 8px 16px', color: 'white' }}
          filename={`Rezylist_${moment().format('YYYY-MM-DD_hh_mm_a')}`}
          headers={[
            'doc_id',
            'venue_uid',
            'name',
            'email',
            'phone',
            'adults',
            'children',
            'rooms',
            'requested_room',
            'checkin',
            'checkout',
            'notify_email',
            'notify_phone',
            'special_requests',
            'notes',
            'last_notification_date',
            // 'booking_url',
            // 'returnclick_url',
            // 'cancel_url',
            'reservation_id',
            'reservation_status',
            'reservation_location',
            'reservation_alternate_room',
            'create_date',
            'create_by',
            'update_date',
            'last_update_by',
            'is_archived',
          ]}
          data={cleanupWaitlistRecords(records)}
        >
          <DownloadIcon />
        </CSVLink> : <span />}
      <IconButton style={{ color: 'white' }} onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Popover id="mobile-popper-menu" open={isOpen} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <Paper style={{ paddingLeft: '20px' }}>
          {showArchivedOption &&
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => handleShowHistorical(e.target.checked)}
                  color='default'
                  defaultChecked={false}
                />
              }
              label='Show historical entries'
            />
          }
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleFilterBooked(e.target.checked)}
                color='default'
                defaultChecked={false}
              />
            }
            label='Show booked entries only'
          />
        </Paper>
      </Popover>
    </>
  );
};

export default MobileActionsBar;
