import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
  addDoc,
  collection,
  getDoc,
  doc,
  query,
  where,
  getDocs
} from 'firebase/firestore';
import { logEvent } from 'firebase/analytics';
import { httpsCallable } from 'firebase/functions';
import { FirebaseContext } from '../firebase/FirebaseContext';
import {
  CheckInCheckOut,
  Adults,
  Rooms,
  Children,
  NotificationPreference,
  RezylistTextField,
  GuestRequests
} from '../components/form/Inputs';

import moment from 'moment';
import { showErrorToast } from '../utils';
import { CircularProgress } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { MultipleSelectCheckmarks } from '../components/form/MultipleSelectCheckmarks';
import { VenueAvailabilityDialog } from './VenueAvailabilityDialog';

const WelcomeText: React.FC<any> = ({
  hotelName
}) => {
  const blnThe = (hotelName || '').toString().toLowerCase().startsWith('the')
  return (
    <Typography textAlign='left' style={{ fontSize: '1.2rem', paddingBottom: '4px' }}>
      Welcome to {blnThe ? '' : 'the'} <b>{hotelName}</b> Reservation Waiting List. If your selected reservation becomes available you will
      be notified immediately through our RezyList system!
    </Typography>
  )
}

export const GuestForm: React.FC<any> = ({venueId, prevalues, onSuccess, onError}) => {
  const [checkin, setCheckin] = React.useState<Date | null>(null);
  const [checkout, setCheckout] = React.useState<Date | null>(null);
  const [adults, setAdults] = React.useState<number>(1);
  const [children, setChildren] = React.useState<number>(0);
  const [rooms, setRooms] = React.useState<number>(1);
  const [guestRequest, setGuestRequest] = React.useState<string>('');
  const [venueData, setVenueData] = React.useState<any>();
  const [settingsData, setSettingsData] = React.useState<any>({});
  const [roomType, setRoomType] = React.useState<any>();
  const [userNotificationPreference, setUserNotificationPreference] = React.useState<any>();
  const [bookingUrl, setBookingUrl] = React.useState<string>('');
  const [name, setName] = React.useState<string>('');
  const [phone, setPhone] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [promoCode, setPromoCode] = React.useState<string>('');
  const [groupCode, setGroupCode] = React.useState<string>('');
  const [waitlistData, setWaitlistData] = useState<any[]>();
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [showAvailabilityDialog, setShowAvailabilityDialog] = React.useState<boolean>(false);
  const { db, analytics, functions } = useContext(FirebaseContext);
  const location = useLocation();
  const labelForAllRoomTypes = 'Any Room Type'

  useEffect(() => {
    (async () => {
      if (venueId && db) {     
        const venueSnap = await getDoc(doc(db,'venues',venueId));
        const venueValues = venueSnap.data();
        setVenueData({...venueValues, venueUid: venueId});

        const venueSettingsSnap = await getDoc(doc(db, 'settings', venueId));
        const venueSettingsData = venueSettingsSnap.data();
        setSettingsData(venueSettingsData)

        const waitlistRef = collection(db, 'venues', venueId, 'waitlist');
        const q = query(waitlistRef, where('is_archived', '==', false));
        const waitlistSnap = await getDocs(q);
        setWaitlistData(waitlistSnap.docs.map(x => x.data()));

        const paramValue = new URLSearchParams(location.search).get('srcURL');
        const srcURL = paramValue?.match(/{(.*)\}/);
        if (srcURL && srcURL.length > 0 && srcURL[1].startsWith('https://')) {
          const incomingUrl = new URL(srcURL[1]);
          if (incomingUrl.searchParams.get('rooms')) setRooms(Number(incomingUrl.searchParams.get('rooms')));
          if (incomingUrl.searchParams.get('adult')) setAdults(Number(incomingUrl.searchParams.get('adult')));
          if (incomingUrl.searchParams.get('child')) setChildren(Number(incomingUrl.searchParams.get('child')));
          if (incomingUrl.searchParams.get('arrive')) setCheckin(moment(incomingUrl.searchParams.get('arrive')).toDate());
          if (incomingUrl.searchParams.get('depart')) setCheckout(moment(incomingUrl.searchParams.get('depart')).toDate());
          if (incomingUrl.searchParams.get('promo')) setPromoCode((incomingUrl.searchParams.get('promo') || '').toString());
          if (incomingUrl.searchParams.get('group')) setGroupCode((incomingUrl.searchParams.get('group') || '').toString());

          incomingUrl.searchParams.delete('promo');
          incomingUrl.searchParams.delete('group');
          setBookingUrl(incomingUrl.toString());
        
        } else {
          if (prevalues) { // came from Demo page
            if (prevalues.checkin) { setCheckin(moment(prevalues.checkin).toDate()); }
            if (prevalues.checkout) { setCheckout(moment(prevalues.checkout).toDate()); }
          }
          setBookingUrl(venueValues?.venue_url || '')
        }
      }
    })()
  }, [db, location.search, prevalues, venueId]);

  const handleAddToWaitlist = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    const form = new FormData(e.target);
    if (!checkin || !checkout || moment(checkout).isSameOrBefore(moment(checkin))) {
      showErrorToast('Check-Out Date must be after Check-In Date');
      return;
    }
    const selectedRoomType = form.get('roomtype');
    setRoomType(selectedRoomType);

    setUserNotificationPreference(form.get('notificationPreference'));

    if (venueData?.allow_roomtypes && venueData?.room_types?.length > 0 && !selectedRoomType) {
      showErrorToast('At least one Room Type is required');
      return;
    }
    setShowAvailabilityDialog(true)
  }

  const getPayload = (continuedWithWarning: boolean | null) => {
    return {
      id: uuid(),
      name,
      phone,
      email,
      adults,
      children,
      rooms,
      checkin,
      checkout,
      notify_email: userNotificationPreference === 'email' || userNotificationPreference === 'both',
      notify_phone: userNotificationPreference === 'phone' || userNotificationPreference === 'both',
      requested_room: roomType && roomType !== labelForAllRoomTypes ? roomType : null,
      special_requests: guestRequest || '',
      notes: '',
      promo_code: promoCode,
      group_code: groupCode,
      last_notification_date: null,
      availability_warning: continuedWithWarning || false,
      booking_url: updatedBookingUrl(),
      create_date: new Date(),
      create_by: 'rezylist',
      last_update_by: 'Rezylist',
      is_archived: false
    };
  }

  // https://rezylist.com/ju5iuVFVf4V9e86l0IMVCgpe3Nj1/default?srcURL={https%3A%2F%2Fbe.synxis.com%2F%3Fadult%3D1%26arrive%3D2024-09-08%26chain%3D16087%26child%3D0%26currency%3DUSD%26depart%3D2024-09-11%26hotel%3D20986%26level%3Dhotel%26locale%3Den-US%26productcurrency%3DUSD%26rooms%3D1}
  const updatedBookingUrl = () => {
    const paramValue = new URLSearchParams(location.search).get('srcURL');
    const srcURL = paramValue?.match(/{(.*)\}/);
    if (srcURL && srcURL.length > 0 && srcURL[1].startsWith('https://')) {
      const newUrl = new URL(srcURL[1]);
      if (newUrl.searchParams.get('rooms')) newUrl.searchParams.set('rooms', rooms.toString());
      if (newUrl.searchParams.get('adult'))  newUrl.searchParams.set('adult', adults.toString());
      if (newUrl.searchParams.get('child')) newUrl.searchParams.set('child', children.toString());
      if (newUrl.searchParams.get('arrive')) newUrl.searchParams.set('arrive', moment(checkin).format('YYYY-MM-DD'));
      if (newUrl.searchParams.get('depart')) newUrl.searchParams.set('depart', moment(checkout).format('YYYY-MM-DD'));
      newUrl.searchParams.delete('promo');
      newUrl.searchParams.delete('group');
      return newUrl.toString();
    } else {
      return bookingUrl;
    }
  }
  const onDialogContinue = (isRoomAvailable: boolean) => {
    setShowAvailabilityDialog(false); 
    const payload = getPayload(isRoomAvailable);
    saveWaitlistToDatabase(payload, isRoomAvailable);
  }

  const saveWaitlistToDatabase = async (payload: any, continuedWithWarning: boolean) => {
    if (!checkin || !checkout || !name) { return; }
    setIsSaving(true);
    const exists = waitlistData?.find(item => {
      return (item.phone === payload.phone || item.email === payload.email) &&
              item.requested_room === payload.requested_room &&
              item.special_requests === payload.special_requests &&
              moment(item.checkin.toDate()).isSame(moment(payload.checkin)) &&
              moment(item.checkout.toDate()).isSame(moment(payload.checkout))
    })
    if (exists && exists.id) {
      const sendConfirmationNotifications = httpsCallable(functions, 'notification-sendConfirmationNotifications');
      sendConfirmationNotifications({ venueDocId: venueId, waitlistUuid: exists.id });
    } else {
      try {
        const waitlistRef = collection(db,'venues',venueId,'waitlist');
        await addDoc(waitlistRef, payload);
        
        if (process.env.NODE_ENV !== 'development') {
          logEvent(analytics, 'joined_waitlist', {
            venue: venueData.venue_name,
            guest: payload.name,
            phone: payload.phone,
            email: payload.email,
            arrives: payload.checkin,
            departs: payload.checkout,
            requested_room: payload.requested_room,
          });
        }
        
      } catch (e) {
        showErrorToast((e as any)?.message || 'We had an error.  Please try again.');
        if (process.env.NODE_ENV !== 'development') {
          logEvent(analytics, 'failed_to_join_waitlist', {
            venue: venueData.venue_name,
            error: (e as any).message as string,
          });
        }
      }
    }

    onSuccess(payload);
    setIsSaving(false);
  }

  if (!venueId) { return <></> }
  return (
    <>
    <form
      style={{ display: 'flex', flexDirection: 'column'}}
      onSubmit={(e: React.FormEvent) => { handleAddToWaitlist(e)}}
    >
      <WelcomeText hotelName={venueData?.venue_name} />
      <Typography textAlign='left' marginTop='20px' style={{ fontSize: '1.2rem' }}>
        Please enter in your information below.
      </Typography>
        {/* {showErrorNotify &&
          <Typography variant='body2' textAlign='left' style={{ padding: '0 55px', color: 'red' }}>
            Please enable at least one notification
          </Typography>
        } */}
          <RezylistTextField id={'name'} value={name} label='Name' autoFocus={true} onChange={(e: any) => setName(e.target.value)} />
          <RezylistTextField id={'phone'} value={phone} label='Phone' type='tel' onChange={(e: any) => setPhone(e.target.value)} />
          <RezylistTextField id={'email'} value={email} label='Email' type='email'  onChange={(e: any) => setEmail(e.target.value)} />
          <Typography textAlign='left' style={{ marginTop: 8, fontSize: '1rem' }}>
            Notification preference:
          </Typography>
          <NotificationPreference />
        <div>
          <CheckInCheckOut
            setCheckin={setCheckin}
            checkin={checkin}
            setCheckout={setCheckout}
            checkout={checkout}
          />
        </div>
        <div style={{textAlign: 'left'}}>
        {venueData?.allow_roomtypes && venueData?.room_types?.length > 0 ?
          <MultipleSelectCheckmarks 
            optionList={venueData.room_types || []} 
            defaultValue={null}  
            listItemId='display_name'
            sortKey='sort_order' 
            allLabel={labelForAllRoomTypes}
            formId='roomtype' 
            formLabel='Room Type *'
          />
        : <></>}
        </div>
        <div
        style={{
          display: 'flex',
          justifyContent: 'center'
        }}>
          <div>
            <Adults adults={adults} setAdults={setAdults} />
            <Children children={children} setChildren={setChildren} />
            <Rooms rooms={rooms} setRooms={setRooms} />
          </div>
        </div>
      <GuestRequests guestRequest={guestRequest} setGuestRequest={setGuestRequest} />

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 20,
          marginBottom: 75,
        }}
      >
        <Button disabled={isSaving} style={{ minWidth: 140, minHeight: 40 }} type='submit' variant='contained'>
          {isSaving ? <CircularProgress /> : `Join Waitlist`}
        </Button>
      </div>
    </form>
    
    {showAvailabilityDialog && 
    <VenueAvailabilityDialog
      openModal={showAvailabilityDialog}
      handleCancel={() => {
        setShowAvailabilityDialog(false); 
        window.location.href = venueData.venue_url;
      }}
      handleContinue={onDialogContinue}
      venueSettingsData={settingsData}
      guestForm={{
        ...venueData,
        ...getPayload(false)
      }}
    />}
    </>
  );
};
