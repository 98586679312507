import React, { useContext, useState } from 'react';
import { FirebaseContext } from '../firebase/FirebaseContext';
import Link from '@mui/material/Link';
import { AppBar, Box, Toolbar , CircularProgress, Paper, TableContainer, Typography } from '@mui/material';
import { DataGridPro, GridActionsCellItem, GridCellEditStartParams, GridColDef, GridRowId, GridRowParams, MuiEvent } from '@mui/x-data-grid-pro';
import { collection, doc, getDoc, getDocs, onSnapshot, query, updateDoc, where } from 'firebase/firestore';
import { Visibility } from '@mui/icons-material';
import { DashboardForm } from '../dashboard/DashboardForm';
import { getError, showErrorToast, showInfoToast } from '../utils';

export const BillingTable: React.FC<any> = () => {
  const [billingData, setBillingData] = useState<any[]>();
  const [editState, setEditState] = useState<any>({});
  const [isOpen, setIsOpen] = useState(false);
  const { currentManager, db } = useContext (FirebaseContext);

  React.useEffect(() => {
    const unsub = onSnapshot(
      query(collection(db, 'billing')),
      async (resSnap) => {
        const records:any[] = [];
        resSnap.forEach(doc => {
          const data = doc.data()

          records.push({uid: doc.id, ...data, revenue: Number(data.revenue_amount * .01 * (data.revenue_percentage || 0)) })
        })
        setBillingData(records);
      }
    )
    return () => unsub()
  }, [currentManager, db]);

  const formatMoney = (currency: string, amount: number) => {
    if (!currency || !amount) { return '' }
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
    });
    return formatter.format(amount)
  }


  const handleAction = React.useCallback((docUid: GridRowId) => async () => {
    const rowData = billingData?.find((i) => i.uid === docUid);
    console.log(rowData);
    if (rowData.waitlist_doc_id) {
      const waitlistSnap = await getDoc(doc(db, 'venues',rowData.venue_doc_id,'waitlist', rowData.waitlist_doc_id));
      const waitlistData = waitlistSnap.data();
      const dataset = {...waitlistData, doc_id: rowData.waitlist_doc_id, venue: rowData.venue_doc_id, venue_uid: rowData.venue_doc_id}
      setEditState(dataset);
      setIsOpen(true);
    }
  },[billingData, db]);

  const handleUpdateWaitlistData = React.useCallback(
    async (venue_uid: string, payload: any) => {

      try {
        const waitlistRef = collection(db, 'venues', venue_uid, 'waitlist');
        const q = query(waitlistRef, where('id', '==', payload.id));
        const waitlistSnap = await getDocs(q);
        if (waitlistSnap.docs.length > 0) {
          const item = waitlistSnap.docs[0]
          await updateDoc(doc (db, 'venues', venue_uid, 'waitlist', item.id), payload);
        } else {
          throw Error('Record not found!')
        }
        showInfoToast('Successfully updated guest');
      } catch (e) {
        showErrorToast(getError(e).message)
      }
    },
    [db]
  );
  const columns: GridColDef[] = [
    {
      field: 'uid',
      type: 'actions',
      flex: 0.1,
      getActions: (params: GridRowParams<any>) => {
        return [
          <GridActionsCellItem
            key={params.id}
            icon={params.row.waitlist_doc_id ? <Visibility fontSize='small' /> : <></>}
            label="View"
            hidden={!params.row.is_archived}
            onClick={handleAction(params.id)}
          />,  
        ]        
      }
    },
    {
      field: 'venue_name',
      flex: 1,
      headerName: 'Venue',
      editable: false,
    },
    {
      field: 'guest_name',
      flex: 1,
      headerName: 'Guest',
      editable: false,
    },
    {
      field: 'invoice_id',
      flex: 1,
      headerName: 'Invoice',
      editable: false,
      renderCell: (params) => {
        return params.value && params.row.invoice_pdf ? <Link target="_blank" href={params.row.invoice_pdf}>{params.value}</Link> : <></>;
      },
    },
    {
      field: 'reservation_id',
      flex: 0.75,
      headerName: 'Reservation Id',
      editable: false,
    },
    {
      field: 'arrival_date',
      flex: 1,
      headerName: 'Arrival Date',
      editable: false,
    },
    {
      field: 'departure_date',
      flex: 1,
      headerName: 'Departure Date',
      editable: false,
    },
    {
      field: 'revenue_amount',
      flex: 1,
      headerName: 'Room Total',
      editable: false,
      renderCell: (params) => (
          <>{formatMoney(params.row.revenue_currency, params.value)}</>
      ),
    },
    {
      field: 'revenue',
      flex: 1,
      headerName: 'Revenue',
      editable: false,
      renderCell: (params) => (
        <>{formatMoney(params.row.revenue_currency, params.value)}</>
      ),
    },
  ]
  
  return (
    <>
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <div style={{marginRight: 16}}>
            <Typography
              component="h1"
              variant='h5'
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Billing Data
            </Typography>
          </div>
          <div style={{ flexGrow: 1 }} />
          
        </Toolbar>
      </AppBar>
    </Box>
    <TableContainer component={Paper} sx={{display: 'table', width: '100%', padding: 2}}>
    {billingData ? <Box 
      sx={{ 
        width: '100%',
        '& .rz_bold': {
          fontWeight: 'bold',
        },
        height: 'calc(100vh - 200px)'
      }}>
        <DataGridPro 
          disableColumnSelector={true}
          getRowId={(row) => row.uid}
          getRowHeight={() => {
            return 30;
          }}
          disableRowSelectionOnClick
          rows={[...billingData]} 
          columns={columns}
          pagination
          pageSizeOptions={[20, 50, 100]}
          initialState={{
            pagination: { paginationModel: { pageSize: 100 }},
            sorting: {
              sortModel: [{ field: 'arrival_date', sort: 'desc' }],
            },
          }}
          onCellEditStart={(params: GridCellEditStartParams, event: MuiEvent) => {
            event.defaultMuiPrevented = true;
          }}
        />
    </Box>
    : <div style={{height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}> <CircularProgress /> </div> 
    }
    </TableContainer>
    {isOpen && (
      <DashboardForm
        isOpen={isOpen} 
        setIsOpen={(val:boolean) => {
          setEditState(null);
          setIsOpen(val);
        }} 
        venueUid={editState.venue}
        values={editState} 
        onAddToWaitlist={handleUpdateWaitlistData}
      />
    )}
  </>
  )
};
