import React, { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  IconButton,
  Paper,
  TableContainer,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import { Box} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LockResetIcon from '@mui/icons-material/LockReset';

import { FirebaseContext } from '../../firebase/FirebaseContext';
import { collection, doc, getDocs, onSnapshot, query, setDoc, where } from 'firebase/firestore';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { NewUserDialog } from '../NewUserDialog';
import { actionTypes, getError, showErrorToast, showSuccessToast } from '../../utils';
import { DataGridPro, GridCellParams, GridColDef, GridValueSetterParams } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { useMobile } from '../../utils/useMobile';
import MobileActionBar from './MobileActionBar';
import DesktopActionBar from './DesktopActionBar';

interface MgrTableProps {
  authUserList: any[];
  refreshAuthlist: () => void;
}
export const UserSettings = ({authUserList, refreshAuthlist}: MgrTableProps) => {
  const [actives, setActives] = useState<any[]>();
  const [inactives, setInactives] = useState<any[]>([]);
  const [venueList, setVenueList] = React.useState<any[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showInactive, setShowInactive] = useState<boolean>(false);
  const { currentManager, db } = useContext (FirebaseContext);
  const { isMobile } = useMobile();
  const navigate = useNavigate();
  const auth = getAuth();

  // currentManager is assumed to be the Venue Admin
  // Mgr must have at least one venue that is in the currentManager venue list.  
  const isStaffMember = useCallback((mgr: any) => {        
    if (!mgr || !mgr.venues) { return false }
    for (let i = 0; i < mgr.venues.length; i++) {
      let venueUid = mgr.venues[i];
      if (currentManager && currentManager.venues.includes(venueUid) && currentManager[venueUid] && currentManager[venueUid].includes(actionTypes.VENUE_ADMIN)) {
        return true;
      }
    }
    return false;
  }, [currentManager])

  const sendResetPassword = useCallback((email: string) => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        showSuccessToast('Password reset email sent.');
    }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.debug(`${errorCode}: ${errorMessage}`);
        showErrorToast('There was a problem sending the email.');
      });
  }, [auth]);
  React.useEffect(() => {
    const subscribeManagers = onSnapshot(collection(db, 'managers'),
      async (managerDocs) => {
        if (!authUserList || authUserList.length === 0) { return }
        const activeMgrs:any[] = [];
        const inactiveMgrs:any[] = [];
        
        managerDocs.forEach(doc => {
          const managerData = doc.data()
          if (isStaffMember(managerData) && !managerData.is_super_admin) {
            const authUser = authUserList.find(x => x.uid === doc.id)
            let newItem = {uid: doc.id, ...managerData, ...authUser}
            if (newItem.is_deactivated) {
              inactiveMgrs.push(newItem);
            } else {
              activeMgrs.push(newItem);
            }
          }
        })
        setActives(activeMgrs);
        setInactives(inactiveMgrs);
      }
    )
    return () => subscribeManagers()
  }, [authUserList, db, currentManager, isStaffMember]);

  React.useEffect(() => {
    (async () => {
      const venueQuery = query (collection (db, 'venues'), where ('venue_name', '!=', 'null'))
      const venuesSnap = await getDocs (venueQuery);
      const venueDataset = venuesSnap.docs.map(x => ({uid: x.id, ...x.data()}))

      setVenueList(venueDataset.filter(x => currentManager && currentManager.venues.includes(x.uid) && currentManager[x.uid] && currentManager[x.uid].includes(actionTypes.VENUE_ADMIN)));
    })()
  }, [currentManager, db])

  const columns: GridColDef[] = [
    {
      field: 'uid',
      headerName: '',
      width: 80,
      sortable: false,
      editable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <IconButton size='small' data-id={params.row.uid} onClick={() => navigate(`${params.row.uid}`)}>
              <VisibilityIcon fontSize='small' />
            </IconButton>
            <Tooltip title="Reset Password">
              <IconButton size='small' data-id={`${params.row.uid}-reset`} onClick={() => sendResetPassword(params.row.email)} >
                <LockResetIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          </div>

        )
      }
    },
    { 
      field: 'last_name', 
      headerName: 'Name', 
      flex: 1,
      minWidth: 150,
      hideable: false,
      valueGetter: (params) => {
        return `${params.row.first_name} ${params.row.last_name}`
      },
      valueSetter: (params: GridValueSetterParams) => {
        const [first_name, last_name] = params.value!.toString().split(' ');
        return { ...params.row, first_name, last_name };
      },
      renderCell: (params) => {
        return (
          <div style={{
                fontWeight: params.row.is_deactivated ? 'bold' : 'normal', 
                color: params.row.is_deactivated ? 'red' : 'inherit'
          }}>
            {params.value}
          </div>
        )
      } 
    },
    { field: 'email', headerName: 'Email', flex: 1, minWidth: 150,},
    { 
      field: 'venues', 
      headerName: 'Venues',
      minWidth: 200,
      flex: 1,
      cellClassName: (params: GridCellParams<any, string>) => {
        return params.row.is_super_admin ? 'rz_bold' : '' 
      },
      valueGetter: (params) => {
        return params.row.is_super_admin ? 'Super Admin' : params.row.venues ? params.row.venues.join(', ') : ''
      },
      valueSetter: (params: GridValueSetterParams) => {
        return {...params.row, venues: typeof params.value === 'string' ? params.value!.split(', ') : params.value };
      },
    },
    {
      field: 'last_login',
      headerName: 'Last Login',
      minWidth: 175,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return <div>{params.value ? moment(params.value.toDate()).format('MMM Do YYYY, h:mm:ss a') : ''}</div>
      }
    },
  ]

  const handleNewManagerAdded = useCallback(
    async (payload: any) => {
      try {
        const docId = payload.uid
        delete payload.uid;
        await setDoc(doc(collection(db, 'managers'), docId), payload);
        refreshAuthlist();
        showSuccessToast('New Manager Added');
        navigate(`${docId}`);
      } catch (e) {
        showErrorToast(getError(e).message)
      }
    }, [db, navigate, refreshAuthlist]
  )
  return (
    <>
      {
        isMobile ?
        <MobileActionBar setIsOpen={setIsOpen} setShowInactive={setShowInactive} /> :
        <DesktopActionBar setIsOpen={setIsOpen} setShowInactive={setShowInactive} />
      }
    <TableContainer component={Paper} sx={{width: '100%', padding: 2}}>
    {actives ? <Box 
      sx={{ 
        width: '100%',
        '& .rz_bold': {
          fontWeight: 'bold',
        },
      }}>
        <DataGridPro 
          autoHeight
          disableColumnSelector={true}
          disableRowSelectionOnClick
          getRowId={(row) => row.uid}
          rows={showInactive ? inactives || [] : actives || []} 
          columns={columns}
          pagination
          pageSizeOptions={[20, 50, 100]}
          initialState={{
            pagination: { paginationModel: { pageSize: 20 }},
            sorting: {
              sortModel: [{ field: 'email', sort: 'asc' }],
            },
          }}
        />
      </Box>
    : <div style={{height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}> <CircularProgress /> </div> }
    {isOpen && (
        <NewUserDialog 
          isOpen={isOpen} 
          setIsOpen={setIsOpen} 
          venueList={venueList}
          onSubmitManager={handleNewManagerAdded}
        />
      )}
  </TableContainer>
  </>
  )
};
