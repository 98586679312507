import React from 'react';
import {
  AppBar,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  Toolbar,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

type MobileActionBarProps = {
  setIsOpen: (value: boolean) => void;
  setShowInactive: (value: boolean) => void;
}

const MobileActionBar = ({ setIsOpen, setShowInactive }: MobileActionBarProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const isOpen = Boolean(anchorEl);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar variant="dense">
          <div style={{ flexGrow: 1}} />
          <IconButton style={{ color: 'white' }} onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Popover id="mobile-popover-menu" open={isOpen} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
            <Paper style={{ paddingLeft: '20px' }}>
              <MenuItem onClick={() => {
                setIsOpen(true);
                setAnchorEl(null);
              }}>Add User</MenuItem>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => setShowInactive(e.target.checked)}
                    color='default'
                    defaultChecked={false}
                  />
                }
                label="Show inactive users"
              />
            </Paper>
          </Popover>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default MobileActionBar;
