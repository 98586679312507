import React, { useContext } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import {  RezylistTextField } from '../components/form/Inputs';
import { FirebaseContext } from '../firebase/FirebaseContext';
import { MultipleSelectCheckmarks } from '../components/form/MultipleSelectCheckmarks';
import { httpsCallable } from 'firebase/functions';
import { showErrorToast } from '../utils';
import { CircularProgress } from '@mui/material';

export const NewUserDialog: React.FC<any> = ({
  isOpen,
  setIsOpen,
  onSubmitManager,
  venueList
}) => {
  const { functions, user } = useContext(FirebaseContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  
  const handleSubmitManager = async (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      try {
        setIsLoading(true);
        const form = new FormData(e.target);
        const venues = form.get('venues') ? (form.get('venues') || '').toString().split(',') : []
        if (venues.length === 0) {
          showErrorToast('At least one venue is required');
          return;
        }
        const email = form.get('email');
        const callable = httpsCallable(functions , 'auth-createAuthAccount');
        const returnData = (await callable({email, venues})).data;
        if ((returnData as any).errorInfo) {
          showErrorToast((returnData as any).errorInfo.message);
          return;
        }
        
        const payload: any = {
          uid: returnData,
          first_name: form.get('first_name'),
          last_name: form.get('last_name'),
          email: email,
          venues: venues,
          create_date: new Date(),
          update_date: new Date(),
          create_by: user?.email || '',
        }
        await onSubmitManager(payload);
        setIsOpen(false);
      } finally {
          setIsLoading(false);
      }
    }

  const resetForm = () =>{
    setIsOpen(false); 
  }
  return (
    <Dialog open={isOpen} onClose={(event, reason) => {
      if (reason === 'backdropClick') { 
        return
      } else {
        resetForm()
      }
    } }>
      <form style={{display: 'flex', flexDirection: 'column'}} onSubmit={handleSubmitManager}>
      <DialogTitle>Add User</DialogTitle>
        <DialogContent sx={{pt: 0}}>
          <RezylistTextField id='email' label='Email' type='email' />
          <RezylistTextField id='first_name' label='First Name' />
          <RezylistTextField id='last_name' label='Last Name' />
          <MultipleSelectCheckmarks 
              optionList={venueList || []}
              listItemId='uid'
              formId='venues' 
              formLabel='Venues'
            />
        </DialogContent>
        <DialogActions>
          <Button disabled={isLoading} onClick={() => resetForm()}>Cancel</Button>
          <Button style={{minWidth: 102}} disabled={isLoading} type="submit" variant="contained">
            {isLoading ? <CircularProgress size={24} /> : 'Add User'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
