import React, { useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';

import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where
} from 'firebase/firestore';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

import { logEvent } from 'firebase/analytics';

import { FirebaseContext } from '../firebase/FirebaseContext';
import { showErrorToast } from '../utils';

import { CircularProgress } from '@mui/material';
import { Copyright } from '../components/Copyright';

export const ReturnClickRedirect: React.FC<any> = () => {
  const { db, analytics } = useContext(FirebaseContext);
  const navigate = useNavigate();
  const { venue, waitlist, payloadUuid } = useParams();
  const [venueData, setVenueData] = React.useState<any>();
  const [stay, setStay] = React.useState<string>('');
  
  useEffect(() => {
    (async () => {
      if (venue && db) {
        const venueDocRef = doc(db,'venues',venue);
        const venueDoc = await getDoc(venueDocRef);
        const venueData = venueDoc.data()
        setVenueData(venueData);
        const waitlistRef = collection(db, 'venues', venue, 'waitlist');
        const q = query(waitlistRef, where('id', '==', payloadUuid));
        const waitlistSnap = await getDocs(q);
        if (venueData && waitlistSnap.docs.length > 0 && waitlistSnap.docs[0].id === waitlist) {
          const waitlistData = waitlistSnap.docs[0].data()
          await updateDoc(doc(db, 'venues', venue, 'waitlist', waitlist), {
            return_click: arrayUnion(new Date())
          });
          setStay(getStayString(waitlistData))
          logEvent(analytics, 'booking_click', {
            venue: venue,
            waitlist: waitlist
          });
          await sleep(1000)
          let redirect = venueData.venue_url;
          if (waitlistData.booking_url && waitlistData.booking_url.length > 2) {
            if (String(waitlistData.booking_url).indexOf('travelclick.com') > 0) {
              let newUrl = new URL(waitlistData.booking_url);
              newUrl.searchParams.append('adults', waitlistData.adults || 0);
              newUrl.searchParams.append('children', waitlistData.children || 0);
              newUrl.searchParams.append('datein', moment(waitlistData.checkin.toDate()).format('MM/DD/YYYY'));
              newUrl.searchParams.append('dateout', moment(waitlistData.checkout.toDate()).format('MM/DD/YYYY'));
              newUrl.searchParams.append('rooms', waitlistData.rooms || 1);

              newUrl.searchParams.delete('promo');
              newUrl.searchParams.delete('group');
              if (waitlistData.requested_room) {
                const requested = waitlistData.requested_room.split(',')[0];
                const roomType = venueData.room_types.find((x: any) => x.display_name === requested || x.roomTypeName === requested || x.description === requested);
                if (roomType && roomType.id) {
                  newUrl.searchParams.append('roomtypeid', roomType.id);
                }
              }
              redirect = newUrl;
            } else {
              redirect = waitlistData.booking_url;
            }
          }
          window.location.replace(redirect);
        } else {
          showErrorToast('There was a problem with that link.  Please try again or contact the administrator');
          navigate('/');
        }
      }
    })()
  }, [analytics, db, navigate, payloadUuid, venue, waitlist]);

  const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  const getStayString = (payload: any) => {
    return moment(payload.checkin.toDate()).format('MMMM Do YYYY') + ' - ' + moment(payload.checkout.toDate()).format('MMMM Do YYYY')
  }
  return (
    <>
      <Card
        style={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          flexDirection: 'column',
          height: 'calc(100vh - 84px)',
          overflowY: 'auto',
        }}
      >
        <div
          style={{
            width: '100%',
            background: venueData?.venue_bgcolor ? 'black' : 'white',
            minHeight: 200,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {venueData?.venue_logo && <img alt={venueData?.venue_name} src={venueData.venue_logo} style={{maxHeight: 150}} />}
        </div>
        <div style={{padding: '32px 24px', minHeight: 100}}>
          <Typography style={{ maxWidth: 640, fontSize: '1.4rem', paddingBottom: 8 }}>
            Loading your request
          </Typography>
          {venueData?.venue_name && stay ?
            <Typography style={{ maxWidth: 640, fontSize: '1.1rem' }}>
              <b>{venueData?.venue_name}</b><br />
              <b>{stay}</b>
            </Typography> : <></>
          }
        </div>
        <CircularProgress /> 
        <footer
          style={{
            position: 'fixed',
            left: 0,
            bottom: 0,
            width: ' 100%',
            padding: '12px 0',
            background: 'black'
          }}
        >
          <Copyright color={'white'} />
        </footer>
      </Card>
    </>
  );
};
