import React, { useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';

import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where
} from 'firebase/firestore';
import { logEvent } from 'firebase/analytics';
import { Card, CircularProgress, Link, Typography } from '@mui/material';
import { FirebaseContext } from '../firebase/FirebaseContext';
import { showErrorToast } from '../utils';
import { Copyright } from '../components/Copyright';
import { httpsCallable } from 'firebase/functions';

export const CancelMyWaitlistPage: React.FC<any> = () => {
  const { db, analytics, functions } = useContext(FirebaseContext);
  const navigate = useNavigate();
  const { venue, waitlist, payloadUuid } = useParams();
  const [venueData, setVenueData] = React.useState<any>();
  const [stay, setStay] = React.useState<string>('');
  
  useEffect(() => {
    (async () => {
      if (venue && db) {
        await sleep(500)
        const defaultDocRef = doc(db,'venues',venue);
        const venueDoc = await getDoc(defaultDocRef);
        setVenueData(venueDoc.data());
        const waitlistRef = collection(db, 'venues', venue, 'waitlist');
        const q = query(waitlistRef, where('id', '==', payloadUuid));
        const waitlistSnap = await getDocs(q);
        if (waitlistSnap.docs.length > 0 && waitlistSnap.docs[0].id === waitlist) {
          const data = waitlistSnap.docs[0].data()
          await updateDoc(doc(db, 'venues', venue, 'waitlist', waitlist), {
            is_archived: true,
            archive_date: new Date(),
            update_by: data.email || data.name,
            last_update_by: data.name || data.email || 'User'
          });
          setStay(getStayString(data))
          const logWaitlistArchivedByUser = httpsCallable(functions, 'logging-logWaitlistArchivedByUser');
          logWaitlistArchivedByUser({ ...data, email: data.email, venue: venue });
          logEvent(analytics, 'cancel_waitlist', {
            id: venue,
            waitlist: waitlist,
            update_by: data.email || data.name,
            last_update_by: data.name || data.email || 'User'
          });
        } else {
          showErrorToast('There was a problem with that link.  Please try again or contact the administrator');
          navigate('/');
        }
      }
    })()
  }, [analytics, db, functions, navigate, payloadUuid, venue, waitlist]);

  const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  const getStayString = (payload: any) => {
    return moment(payload.checkin.toDate()).format('MMMM Do YYYY') + ' - ' + moment(payload.checkout.toDate()).format('MMMM Do YYYY')
  }
  return (
    <>
      <Card
        style={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          flexDirection: 'column',
          height: 'calc(100vh - 84px)',
          overflowY: 'auto',
        }}
      >
        <div
          style={{
            width: '100%',
            background: venueData?.venue_bgcolor ? 'black' : 'white',
            minHeight: 200,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {venueData?.venue_logo && <img alt={venueData?.venue_name} src={venueData.venue_logo} style={{maxHeight: 150}} />}
        </div>
        <div style={{padding: '32px 24px'}}>
          {venueData?.venue_name && stay ?
          <>
            <Typography style={{ maxWidth: 640, fontSize: '1.1rem' }}>
              You have successfully unsubscribed from the waiting list for<br />
              <b>{venueData?.venue_name}</b> on <b>{stay}</b>
            </Typography>
              <br /><br />
              <Link color='#000' fontSize={'1.1rem'} href={`/${venue}/default`}>Make a new request at {venueData?.venue_name}</Link>
          </> : <CircularProgress /> 
          }
        </div>
        <footer
          style={{
            position: 'fixed',
            left: 0,
            bottom: 0,
            width: '100%',
            padding: '12px 0',
            background: 'black',
          }}
        >
          <Copyright color={'white'} />
        </footer>
      </Card>
    </>
  );
};
